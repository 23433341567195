import React, { useContext } from 'react';
import Fade from 'react-reveal/Fade';
import { Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import useDeviceRecognizer from '../shared/useDeviceRecognizer';
import FluidImg from '../Image/FluidImg';

const Projects = () => {
  const { services } = useContext(PortfolioContext);

  const { isDesktop, isMobile } = useDeviceRecognizer();
  let fadeCounterFirst = 0;
  let fadeCounterSecond = 0;

  return (
    services.length > 0 && (
      <section id="services">
        <Row className="section-row">
          <Col lg={6} className="column is-centered">
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
              <div className="description">
                <Fade left duration={1000} delay={500} distance="30px">
                  <h3>{services[0].title}</h3>
                </Fade>

                <ul>
                  {services[0].values.map((value) => {
                    fadeCounterFirst += 100;

                    return (
                      <Fade
                        key={value}
                        bottom
                        duration={1000}
                        delay={500 + fadeCounterFirst}
                        distance="30px"
                      >
                        <li>{value}</li>
                      </Fade>
                    );
                  })}
                </ul>
              </div>
            </Fade>
          </Col>
          <Col lg={6} className="column">
            <FluidImg alt={services[0].title} filename={services[0].img} />
          </Col>
        </Row>
        <Row className="section-row">
          <Col lg={6} className="column is-order">
            <FluidImg alt={services[1].title} filename={services[1].img} />
          </Col>
          <Col lg={6} className="column is-centered col-lg-pull-6">
            <Fade right={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
              <div className="description">
                <Fade
                  left={isDesktop}
                  bottom={isMobile}
                  duration={1000}
                  delay={500}
                  distance="30px"
                >
                  <h3>{services[1].title}</h3>
                </Fade>
                <ul>
                  {services[1].values.map((value) => {
                    fadeCounterSecond += 100;

                    return (
                      <Fade
                        key={value}
                        bottom
                        duration={1000}
                        delay={500 + fadeCounterSecond}
                        distance="30px"
                      >
                        <li>{value}</li>
                      </Fade>
                    );
                  })}
                </ul>
              </div>
            </Fade>
          </Col>
        </Row>
      </section>
    )
  );
};

export default Projects;
