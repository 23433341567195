import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-scroll';
import FluidImg from '../Image/FluidImg';

const HeroLogo = ({ isDesktop, isMobile }) => {
  return (
    <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
      <div className="logo">
        <FluidImg filename="logo.png" alt="logo" />
      </div>
      <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
        <p className="hero-cta">
          <span className="cta-btn cta-btn--hero">
            <Link to="about" offset={-100} smooth duration={1000}>
              Zakažite termin
            </Link>
          </span>
        </p>
      </Fade>
    </Fade>
  );
};

export default HeroLogo;

HeroLogo.propTypes = {
  isDesktop: PropTypes.bool,
  isMobile: PropTypes.bool,
};
