import React from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import LeafletMap from '../LeafletMap';
import useDeviceRecognizer from '../shared/useDeviceRecognizer';

const About = () => {
  const {
    desktop: { isDesktop },
    mobile: { isMobile },
  } = useDeviceRecognizer();

  return (
    <section id="about">
      <Container className="content">
        <Title title="Kako do nas?" />
        <Row className="about-wrapper">
          <Col md={6} sm={12}>
            <Fade left duration={1000} delay={600} distance="30px">
              <LeafletMap
                position={[46.393117, 16.432448]}
                zoom={16}
                markerText="Svetojelenska cesta 7"
              />
              <p className="info-text">
                Ambulanta <strong>ULTRASANO</strong> nalazi se na adresi <br />
                <strong>Svetojelenska cesta 7, Čakovec</strong>
              </p>
            </Fade>
          </Col>
          <Col md={6} sm={12}>
            <Fade right={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <div className="work-time">
                <h2>
                  <span>Radno vrijeme</span>
                  <i className="fa fa-clock-o fa-2x" aria-hidden="true" />
                  <br />
                </h2>
                <div>Prema dogovoru</div>
              </div>
              <div className="info">
                <div className="contact">
                  <h2>Kontakt</h2>
                  <div className="number">
                    <i className="fa fa-mobile" />
                    <span>098 242 874 (dr. Hussein Saghir)</span>
                  </div>
                  {/* TODO */}
                  {/* <div className="email">
                    <i className="fa fa-envelope-o" />
                    <span>hsaghir@ultrasano.hr</span>
                  </div> */}
                </div>
                <ul>
                  <li>Stručna i kvalitetna medicinska usluga</li>
                  <li>Rezervirajte termin pregleda u par klikova</li>
                  <li>Bez lista čekanja!</li>
                </ul>
                {/* TODO - to another screen */}
                {/* <span className="d-flex mt-3">
                  <Link to="/patient-order" className="cta-btn cta-btn--resume">
                    Rezerviraj termin
                  </Link>
                </span> */}
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
