import React from 'react';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-scroll';
import UltrasoundImg from '../../Image/UltrasoundImg';
import CovidImage from '../../Image/Carousel/CovidImage';
import ImageMask from './ImageMask';

const HeroCarousel = () => {
  return (
    <Carousel pause="hover">
      <Carousel.Item className="covid-slide">
        <div className="covid-image">
          <CovidImage alt="covid" filename="carousel/covid.png" />
        </div>
        <Carousel.Caption>
          <div className="text-wrapper">
            <h3>Covid 19</h3>
            <div className="description">
              <p>
                Ambulanta <strong> ULTRASANO</strong> radi normalno prema dogovoru. <br /> Upoznajte
                se s našim mjerama.
              </p>
              <p className="cta-button">
                <span className="cta-btn cta-btn--hero">
                  <Link to="covid" offset={-100} smooth duration={1000}>
                    Saznajte više
                  </Link>
                </span>
              </p>
            </div>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <ImageMask>
          <UltrasoundImg alt="ultrasound-img" filename="ultrasound-1.jpg" />
        </ImageMask>
        <Carousel.Caption>
          <h3>Ultrazvučni pregledi</h3>
          <p>Stručna i kvalitetna usluga radi zaštite Vašeg zdravlja.</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default HeroCarousel;
