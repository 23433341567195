import React from 'react';
import Fade from 'react-reveal/Fade';
import { Row, Col } from 'react-bootstrap';
import DoctorsImg from '../Image/Home/DoctorsImg';
import FluidImg from '../Image/FluidImg';
import useDeviceRecognizer from '../shared/useDeviceRecognizer';

const Covid = () => {
  const {
    desktop: { isDesktop },
    mobile: { isMobile },
  } = useDeviceRecognizer();

  return (
    <section id="covid" className="container">
      <h2>Covid 19</h2>
      <Row>
        <Col lg={6} md={12} sm={12}>
          <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
            <DoctorsImg filename="home/doctors" />
          </Fade>
        </Col>
        <Col lg={6} md={12} sm={12} className="text">
          <Fade right={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
            <h3>
              U ambulanti <strong>ultrasano</strong> zdravlje naših pacijenata nam je na prvom
              mjestu.
            </h3>

            <p>Pridržavamo se svih preporuka Nacionalnog stožera.</p>
            <p>
              Molimo Vas da ukoliko imate zakazan termin pregleda u našoj ambulanti da se
              pridržavate propisanih mjera.
            </p>
          </Fade>
        </Col>
      </Row>
      <Row className="icon-list">
        <Col className="item" lg={3} md={6} sm={12}>
          <Fade left={isDesktop} duration={1000} delay={500} distance="30px">
            <FluidImg filename="home/temperature" />
            <span>
              Ukoliko imate temperaturu, molimo Vas da odgodite pregled i po potrebi se javite Vašem
              obiteljskom liječniku.
            </span>
          </Fade>
        </Col>
        <Col className="item" lg={3} md={6} sm={12}>
          <Fade left={isDesktop} duration={1000} delay={1000} distance="30px">
            <FluidImg filename="home/male-patient" />
            <span>Prilikom obavljanja pregleda pacijent dobiva svoju masku.</span>
          </Fade>
        </Col>
        <Col className="item" lg={3} md={6} sm={12}>
          <Fade left={isDesktop} duration={1000} delay={1500} distance="30px">
            <FluidImg filename="home/paramedic" />
            <span>Radi Vaše sigurnosti, naši doktori nose maske prilikom svakog pregleda.</span>
          </Fade>
        </Col>
        <Col className="item" lg={3} md={6} sm={12}>
          <Fade left={isDesktop} duration={1000} delay={2000} distance="30px">
            <FluidImg filename="home/hand-sanitizer" />
            <span>Prilikom ulaska, molimo Vas dezinficirajte ruke.</span>
          </Fade>
        </Col>
      </Row>
    </section>
  );
};

export default Covid;
