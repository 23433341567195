import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import HeroLogo from './HeroLogo';
import HeroCarousel from './HeroCarousel/HeroCarousel';
import useDeviceRecognizer from '../shared/useDeviceRecognizer';

const Header = () => {
  const {
    desktop: { isDesktop },
    mobile: { isMobile },
  } = useDeviceRecognizer();

  return (
    <section id="hero">
      <Row className="hero-container">
        <Col xl={6} lg={12} md={12} sm={12} className="hero-logo">
          <HeroLogo isDesktop={isDesktop} isMobile={isMobile} />
        </Col>
        <Col xl={6} lg={12} md={12} sm={12} className="hero-carousel">
          <Fade right={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
            <Row className="carousel-container">
              <HeroCarousel />
            </Row>
          </Fade>
        </Col>
      </Row>
    </section>
  );
};

export default Header;
