import React from 'react';
import PropTypes from 'prop-types';
import * as L from 'leaflet';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';

const LeafletMap = ({ position, zoom, markerText }) => {
  if (typeof window === 'undefined') {
    return null;
  }

  return (
    <Map
      style={{ height: 400 }}
      center={position}
      zoom={zoom}
      scrollWheelZoom={false}
      dragging={!L.Browser.mobile}
      tap={!L.Browser.mobile}
    >
      <TileLayer
        url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      {markerText !== '' && (
        <Marker position={position}>
          <Popup>{markerText}</Popup>
        </Marker>
      )}
    </Map>
  );
};

export default LeafletMap;

LeafletMap.propTypes = {
  position: PropTypes.array,
  zoom: PropTypes.number,
  markerText: PropTypes.string,
};

LeafletMap.defaultProps = {
  position: [51, -1],
  zoom: 13,
  markerText: '',
};
