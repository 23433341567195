import React, { useState, useEffect } from 'react';
import Hero from './Hero/Hero';
import Navbar from './Navbar/Navbar';
import About from './About/About';
import Services from './Services/Services';
import Contact from './Contact/Contact';
import Footer from './Footer/Footer';
import Covid from './Covid/Covid';
import { useLocation } from '@reach/router';
import { scroller, animateScroll } from 'react-scroll';

import { PortfolioProvider } from '../context/context';

import { heroData, aboutData, servicesData, contactData, footerData } from '../mock/data';

function App() {
  if (typeof window === `undefined`) {
    return <div />;
  }

  const location = useLocation();
  const [hero, setHero] = useState({});
  const [about, setAbout] = useState({});
  const [services, setServices] = useState([]);
  const [contact, setContact] = useState({});
  const [footer, setFooter] = useState({});

  if (location.state?.scrollTo) {
    switch (location.state.scrollTo) {
      case 'hero':
        scroller.scrollTo('hero', {
          duration: 2000,
          smooth: true,
          offset: -100,
        });
        break;

      case 'about':
        scroller.scrollTo('about', {
          duration: 2000,
          smooth: true,
          offset: -100,
        });
        break;

      case 'services':
        scroller.scrollTo('services', {
          duration: 2000,
          smooth: true,
          offset: -100,
        });
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    setHero({ ...heroData });
    setAbout({ ...aboutData });
    setServices([...servicesData]);
    setContact({ ...contactData });
    setFooter({ ...footerData });

    return () => {
      animateScroll.scrollToTop();
    };
  }, []);

  return (
    <PortfolioProvider value={{ hero, about, services, contact, footer }}>
      <Navbar />
      <Hero />
      <About />
      <Covid />
      <Services />
      {/* <Contact /> */}
      <Footer />
    </PortfolioProvider>
  );
}

export default App;
