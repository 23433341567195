import { useState, useEffect } from 'react';

export default function useDeviceRecognizer() {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.innerWidth > 769) {
        setIsDesktop(true);
        setIsMobile(false);
      } else {
        setIsMobile(true);
        setIsDesktop(false);
      }
    }
  }, []);

  return {
    desktop: { isDesktop, setIsDesktop },
    mobile: { isMobile, setIsMobile },
  };
}
